import React, { useEffect, useState } from 'react';
import { insertionSort } from '@sherstack/algo';

import { shiftCells } from '../functions/shiftCells';

const renderArray = arr => {
  const arrayCells = [];
  arr.forEach((obj, idx) => {
    arrayCells.push(renderCell(obj, idx));
  });
  return <div className="array">{arrayCells}</div>;
};

const renderCell = (obj, idx) => {
  return (
    <div className="cell-container" id={`cell-${obj.placement}`} key={idx}>
      <div className="cell">
        <div className="val" style={{ backgroundColor: obj.color }}>
          {obj.letter}
        </div>
        <div className="idx">{idx}</div>
      </div>
    </div>
  );
};

const ArrayObj = props => {
  const [sortingBegun, setSortingBegun] = useState(false);
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (props.arr.length > 0) {
      setArr([...props.arr]);
    }
  }, [props.arr]);

  useEffect(() => {
    if (!sortingBegun && arr.length > 0) {
      const startSort = async () => {
        setSortingBegun(true);
        const options = {
          up: true,
          byProperty: 'placement',
          doThisEachSwap: shiftCells,
          doThisEachSwapParams: ['arr', 'swapItem1', 'swapItem2'],
          swapFirst: false
        };
        await insertionSort(arr, options);
      };

      startSort();
    }
  }, [arr]);

  return (
    <>{props.arr ? <div className="array-container">{renderArray(arr)}</div> : null}</>
  );
};

export default ArrayObj;
