import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ArrayObj from '../components/ArrayObj';

const colors = ['#5656d9', '#f5b33e', '#12105b', '#c03821', '#81ea6a', '#0fbcf9'];

const createGuide = sentence => {
  const arr = sentence.split('');
  let guide = [];

  arr.forEach((letter, i) => {
    const color = colors[i % colors.length];
    guide.push({
      letter,
      placement: i,
      color
    });
  });

  return guide;
};

const shuffle = guide => {
  let arr = [...guide];
  arr.sort(() => {
    return 0.5 - Math.random();
  });
  return arr;
};

const HomePage = () => {
  const [guide, setGuide] = useState([]);
  const [arr, setArr] = useState([]);

  useEffect(() => {
    setGuide(createGuide('Start_Coding'));
  }, []);

  useEffect(() => {
    setArr(shuffle(guide));
  }, [guide]);

  return (
    <div>
      <div>{arr.length > 0 ? <ArrayObj arr={arr} /> : null}</div>
    </div>
  );
};

const mapState = state => {
  return {
    stuffs: state.stuffReducer.stuffs
  };
};

export default connect(mapState)(HomePage);
