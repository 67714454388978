import $ from 'jquery';

const shiftCells = async (arr, moveLeft, moveRight) => {
  await timer(300);

  const moveLeftEl = $(`#cell-${arr[moveLeft].placement}`);
  const moveRightEl = $(`#cell-${arr[moveRight].placement}`);
  for (let i = moveRight; i < moveLeft; i++) {
    let el = $(`#cell-${arr[i].placement}`);
    let nextEl = $(`#cell-${arr[i + 1].placement}`);
    let moveTo = parseInt(nextEl.css('left'), 10);
    $(el).css('left', `${moveTo}px`);
  }
  let moveLeftTo = parseInt(moveRightEl.css('left'), 10);
  $(moveLeftEl).css('left', `${moveLeftTo}px`);
};

const timer = ms => {
  return new Promise(res => setTimeout(res, ms));
};

export { shiftCells };
